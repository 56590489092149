import { HelpOutline, Menu } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import { Spacing } from "@residently/components/tokens";
import { usePathname } from "next/navigation";
import { ReactNode, useCallback, useState } from "react";

import ImageLink from "components/atoms/ImageLink";

import AppBar from "components/organisms/SidebarNav/AppBar";
import Drawer from "components/organisms/SidebarNav/Drawer";
import DrawerHeader from "components/organisms/SidebarNav/DrawerHeader";
import NavListItem from "components/organisms/SidebarNav/NavListItem";
import NavListItemContent from "components/organisms/SidebarNav/NavListItemContent";
import TopNavBar from "components/organisms/TopNavBar";

import useAuth from "hooks/useAuth";

import I18n from "services/I18n";
import { NavBarItem, navBarListItems } from "services/navBarListItems";

export interface Props {
  children: ReactNode;
}

export default function SidebarNav({ children }: Props) {
  const pathname = usePathname();

  const { roles } = useAuth();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState<number | undefined>(undefined);
  const [subMenuItems, setSubMenuItems] = useState<NavBarItem[] | undefined>(
    undefined
  );

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen, setMobileOpen]);

  const handleSubMenuClick = useCallback(
    (item: NavBarItem | null, itemIndex?: number) => () => {
      if (!item) {
        setSubMenuOpen(undefined);
        return;
      }

      setSubMenuItems(item.items);
      setSubMenuOpen(subMenuOpen === itemIndex ? undefined : itemIndex);
    },
    [subMenuOpen]
  );

  const itemIsSelected = (item: NavBarItem) => {
    const path = pathname?.split("/");
    return (
      path?.some((segment) =>
        item.items
          ?.map((item: NavBarItem) => item.href?.replace("/", ""))
          .includes(segment)
      ) || false
    );
  };

  return (
    <Box sx={{ display: "flex", minHeight: "100%" }}>
      <AppBar>
        <Toolbar
          variant="dense"
          sx={{
            display: "flex",
            minHeight: "48px",
            height: "48px",
            justifyContent: "space-between",
            marginRight: Spacing.xsmall,
          }}
        >
          <IconButton
            color="inherit"
            aria-label="Main menu"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ ml: 0.2, mr: 1, display: { sm: "none" } }}
          >
            <Menu />
          </IconButton>
          <TopNavBar />
        </Toolbar>
      </AppBar>

      <Drawer
        onClose={handleDrawerToggle}
        mobileOpen={mobileOpen}
        subMenuOpen={subMenuOpen}
        onSubMenuClick={handleSubMenuClick(null)}
        subMenuItems={subMenuItems}
      >
        {!mobileOpen && (
          <DrawerHeader>
            <ImageLink
              href="/dashboard"
              src="https://s3.eu-west-2.amazonaws.com/resident.ly-production-static-assets/logo/2021/SVG/Residently_r_logo_White_RGB.svg"
              height={36}
              alt="Residently logo"
              data-testid="navBar-logo"
            />
          </DrawerHeader>
        )}

        <List
          component="nav"
          aria-label="Main"
          sx={{
            backgroundColor: "blue.blue800",
            marginTop: mobileOpen ? Spacing.xxlarge : 0,
          }}
        >
          {navBarListItems(roles).map((item, index) => {
            if (item.items.length) {
              return (
                <NavListItemContent
                  key={item.key}
                  text={item.text}
                  open
                  icon={item.icon}
                  preview={item.preview}
                  selected={itemIsSelected(item)}
                  onClick={handleSubMenuClick(item, index)}
                  hasSubItems
                />
              );
            }

            return (
              <NavListItem
                key={item.href}
                href={item.href}
                text={item.text}
                icon={item.icon}
                enabled={item.enabled}
                preview={item.preview}
                open
              />
            );
          })}

          <Divider
            sx={{
              backgroundColor: "blue.blue600",
              marginX: Spacing.medium,
              marginY: Spacing.small,
            }}
          />

          <NavListItem
            key="zendesk"
            href="https://residently.zendesk.com/hc/en-us"
            text={I18n.t("components.navbar.help")}
            icon={<HelpOutline />}
            enabled
            newTab
            preview={false}
            open
          />
        </List>
      </Drawer>
      {children}
    </Box>
  );
}

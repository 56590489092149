import getConfig from "next/config";

declare let window: any;

const nextConfig = getConfig();

let featureFlags;

if (nextConfig) {
  featureFlags = nextConfig.publicRuntimeConfig.featureFlags;
} else {
  const env = window.__ENV || {};

  featureFlags = {
    enableCommunityCards: env.ENABLE_COMMUNITY_CARDS,
    enableInsights: env.ENABLE_INSIGHTS,
    enablePaymentNavItem: env.ENABLE_PAYMENT_NAV_ITEM,
    enablePropertyStateFilter: env.ENABLE_PROPERTY_STATE_FILTER,
    enableViewingEvents: env.ENABLE_VIEWING_EVENTS,
    enableNewLeadsOnActiveLets: env.ENABLE_NEW_LEADS_ON_ACTIVE_LETS,
    enableRenewalReviews: env.ENABLE_RENEWAL_REVIEWS,
    enableReReferencing: env.ENABLE_RE_REFERENCING,
  };
}

export const propertyStateFilterEnabled =
  featureFlags.enablePropertyStateFilter === "true";

export const paymentsNavItemEnabled =
  featureFlags.enablePaymentNavItem === "true";

export const viewingEventsEnabled = featureFlags.enableViewingEvents === "true";

export const insightsNavItemEnabled = featureFlags.enableInsights === "true";

export const communityCardsEnabled =
  featureFlags.enableCommunityCards === "true";

export const newLeadsOnActiveLetsEnabled =
  featureFlags.enableNewLeadsOnActiveLets === "true";

export const renewalReviewsEnabled =
  featureFlags.enableRenewalReviews === "true";

export const reReferencingEnabled = featureFlags.enableReReferencing === "true";

import { AlertColor } from "@mui/material/Alert";
import { createContext, useCallback, useMemo, useRef, useState } from "react";

const defaultSeverity = "success";
const defaultDuration = 5000;

type SetAlertType = (options: {
  text: string | null;
  duration?: number;
  severity?: AlertColor;
}) => void;

export interface GlobalTemporaryAlertContextProps {
  text: string | null;
  setAlert: SetAlertType;
  severity: AlertColor;
  show: boolean;
}

interface GlobalTemporaryAlertContextProviderProps {
  children: React.ReactNode;
}

const GlobalTemporaryAlertContext =
  createContext<GlobalTemporaryAlertContextProps>({
    text: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setAlert: () => {},
    severity: defaultSeverity,
    show: false,
  });

export default GlobalTemporaryAlertContext;

export const GlobalTemporaryAlertContextProvider = ({
  children,
}: GlobalTemporaryAlertContextProviderProps) => {
  const [text, setText] = useState<string | null>(null);
  const [severity, setSeverity] = useState<AlertColor>(defaultSeverity);
  const [show, setShow] = useState(false);

  const timerRef = useRef<number>();

  const timerCallback = useCallback(() => {
    setShow(false);
    setText(null);
  }, [setShow, setText]);

  const setAlert = useCallback<SetAlertType>(
    ({
      text: alertText,
      severity: alertSeverity = defaultSeverity,
      duration: alertDuration = defaultDuration,
    }) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      setText(alertText);

      if (!alertText) {
        setShow(false);
        return;
      }

      setSeverity(alertSeverity);

      setShow(true);

      timerRef.current = window.setTimeout(timerCallback, alertDuration);
    },
    [setText, setSeverity, setShow]
  );

  const value = useMemo(
    () => ({ text, setAlert, severity, show }),
    [text, setAlert, severity, show]
  );

  return (
    <GlobalTemporaryAlertContext.Provider value={value}>
      {children}
    </GlobalTemporaryAlertContext.Provider>
  );
};

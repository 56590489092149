"use client";

import CheckCircle from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Typography } from "@mui/material";
import MuiAlert, { AlertProps as MuiAlertProps } from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import {
  BorderRadius,
  BorderWidth,
  IconSize,
  Spacing,
} from "@residently/components/tokens";
import { forwardRef, useId } from "react";

import joinClasses from "services/styles/joinClasses";

const PREFIX = "MuiAlert";

const classes = {
  root: `${PREFIX}-root`,
  message: `${PREFIX}-message`,
  icon: `${PREFIX}-icon`,
  outlinedSuccess: `${PREFIX}-outlinedSuccess`,
  outlinedError: `${PREFIX}-outlinedError`,
  outlinedInfo: `${PREFIX}-outlinedInfo`,
  action: `${PREFIX}-action`,
};

const StyledMuiAlert = styled(MuiAlert)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  boxSizing: "border-box",
  borderRadius: BorderRadius.regular,
  borderWidth: BorderWidth.thin,
  paddingTop: 0,
  paddingBottom: 0,

  [`& .${classes.message}`]: {
    color: theme.palette.black.black100,
    flexGrow: 1,
  },

  [`& .${classes.icon}`]: {
    marginTop: "10px",
    padding: 0,
  },

  [`&.${classes.outlinedSuccess}`]: {
    background: theme.palette.mint.mint50,
    borderColor: theme.palette.mint.mint400,
  },

  [`&.${classes.outlinedError}`]: {
    background: theme.palette.terracotta.terracotta50,
    borderColor: theme.palette.terracotta.terracotta500,
  },

  [`&.${classes.outlinedInfo}`]: {
    background: theme.palette.blue.blue50,
    borderColor: theme.palette.blue.blue300,
  },

  [`& .${classes.action}`]: {
    paddingTop: "2px",
  },
}));

const iconMapping = {
  success: (
    <CheckCircle
      sx={{
        color: ({ palette }) => palette.mint.mint900,
        fontSize: `${IconSize.xsmall}px`,
      }}
    />
  ),
  error: (
    <ErrorIcon
      sx={{
        color: ({ palette }) => palette.terracotta.terracotta900,
        fontSize: `${IconSize.xsmall}px`,
      }}
    />
  ),
  info: (
    <InfoIcon
      sx={{
        color: ({ palette }) => palette.blue.blue500,
        fontSize: `${IconSize.xsmall}px`,
      }}
    />
  ),
};

export interface Props extends MuiAlertProps {
  text?: string;
  title?: string;
}

const Alert = forwardRef(
  (
    { variant = "outlined", title, text, className, children, ...props }: Props,
    ref: any
  ) => {
    const labelId = useId();
    const descriptionId = useId();

    return (
      <StyledMuiAlert
        {...props}
        ref={ref}
        variant={variant}
        iconMapping={iconMapping}
        className={joinClasses([classes.root, className])}
        aria-labelledby={labelId}
        aria-describedby={descriptionId}
      >
        {title && (
          <Typography
            id={labelId}
            variant="body2"
            fontWeight="bold"
            marginBottom={Spacing.small}
          >
            {title}
          </Typography>
        )}
        <Box id={descriptionId}>
          {text && <Typography variant="body2">{text}</Typography>}
          {children}
        </Box>
      </StyledMuiAlert>
    );
  }
);
Alert.displayName = "Alert";

export default Alert;

import { useGetResource } from "hooks/api";

import { JsonApiParams } from "services/JsonApi";
import {
  organisationPath,
  organisationsPath,
} from "services/organisations/paths";
import { Organisation } from "services/organisations/types";

export const useOrganisations = (params?: JsonApiParams) =>
  useGetResource<Organisation[]>(organisationsPath(), params);

export const useOrganisation = (id?: string | number, params?: JsonApiParams) =>
  useGetResource<Organisation>(organisationPath(id), params);

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LoyaltyOutlinedIcon from "@mui/icons-material/LoyaltyOutlined";
import { Link, Menu, MenuItem, MenuList, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { BorderRadius, IconSize, Spacing } from "@residently/components/tokens";
import { MouseEventHandler, useState } from "react";

import AccountOrganisationPicker from "components/organisms/AccountOrganisatonPicker";

import useAuth from "hooks/useAuth";

import I18n from "services/I18n";

const PREFIX = "AccountMenu";

const classes = {
  initialsButton: `${PREFIX}-initialsButton`,
  initials: `${PREFIX}-initials`,
  menuItemLink: `${PREFIX}-menuItemLink`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.initialsButton}`]: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.blue.blue400,
    backgroundColor: theme.palette.white.white100,
    borderRadius: BorderRadius.pill,
    border: 0,
    width: IconSize.large,
    height: IconSize.large,
  },

  [`& .${classes.menuItemLink}`]: {
    color: "inherit",
  },
}));

export interface Props {
  name?: string;
  initials: string;
  organisationInternalName?: string;
  signOut: () => void;
}

const AccountMenu = ({
  name,
  initials,
  organisationInternalName,
  signOut,
}: Props) => {
  const { roles } = useAuth();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const menuOpen = !!anchorEl;

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Root>
      <button
        aria-label="Account menu"
        aria-haspopup="menu"
        aria-controls="customized-menu"
        className={classes.initialsButton}
        onClick={handleClick}
        type="button"
      >
        <Typography
          variant="overline"
          component="span"
          // offset the 2px added by the letterSpacing after the last character within the Typography overline variant
          mr="-2px"
        >
          {initials}
        </Typography>
      </button>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={menuOpen}
        onClose={handleClose}
        variant="menu"
      >
        <Typography
          variant="body1"
          sx={{ flex: 1, padding: `${Spacing.medium}` }}
        >
          {name}
        </Typography>

        {roles?.includes("staff") && (
          <AccountOrganisationPicker
            organisationInternalName={organisationInternalName}
          />
        )}

        <hr />

        <Typography variant="h5" sx={{ flex: 1, padding: `${Spacing.medium}` }}>
          {I18n.t("components.accountMenu.accountMenuTitle")}
        </Typography>

        <MenuList id="menu-list-grow">
          <MenuItem onClick={signOut}>
            <ExitToAppIcon />
            {I18n.t("components.accountMenu.signOutItem")}
          </MenuItem>
        </MenuList>

        <Typography variant="h5" sx={{ flex: 1, padding: `${Spacing.medium}` }}>
          {I18n.t("components.accountMenu.moreOptionsTitle")}
        </Typography>

        <MenuList>
          <Link
            className={classes.menuItemLink}
            underline="none"
            href="https://residently.com/terms-and-conditions"
          >
            <MenuItem>
              <DescriptionOutlinedIcon />
              {I18n.t("components.accountMenu.termsAndConditionsItem")}
            </MenuItem>
          </Link>

          <Link
            className={classes.menuItemLink}
            underline="none"
            href="https://residently.com/privacy-policy"
          >
            <MenuItem>
              <LoyaltyOutlinedIcon />
              {I18n.t("components.accountMenu.privacyPolicyItem")}
            </MenuItem>
          </Link>
        </MenuList>
      </Menu>
    </Root>
  );
};

export default AccountMenu;

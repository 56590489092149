import MuiAppBar, { AppBarProps } from "@mui/material/AppBar";
import { styled, useTheme } from "@mui/material/styles";
import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

const drawerWidth = 100;

const StyledAppBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

export default function AppBar({ children }: Props) {
  const theme = useTheme();
  return (
    <StyledAppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth - 1}px)` }, // -1px to fix the gap between the drawer and the appbar
        ml: { sm: `${drawerWidth}px` },
        background: theme.palette.blue.blue800,
        alignItems: {
          sm: "flex-end",
        },
      }}
    >
      {children}
    </StyledAppBar>
  );
}

import NextLink from "next/link";

export interface Props {
  href: string;
  src: string;
  height?: number;
  width?: number;
  alt: string;
  ["data-testid"]?: string;
}

const Link = ({ href, src, height, width, alt, ...otherProps }: Props) => (
  <NextLink href={href} passHref>
    <span
      style={{
        display: "inline-flex",
        alignItems: "center",
      }}
    >
      <img src={src} height={height} width={width} alt={alt} {...otherProps} />
    </span>
  </NextLink>
);

export default Link;

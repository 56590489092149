import { Snackbar as MuiSnackbar } from "@mui/material";
import { useContext } from "react";

import GlobalTemporaryAlertContext from "context/GlobalTemporaryAlertContext";

import Alert from "components/atoms/Alert";

const GlobalTemporaryAlert = () => {
  const { text, severity, show } = useContext(GlobalTemporaryAlertContext);

  return text ? (
    <MuiSnackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={show}
      sx={{ top: "100px" }}
    >
      <Alert
        severity={severity}
        text={text}
        sx={{ width: "100%", boxShadow: 5 }}
      />
    </MuiSnackbar>
  ) : null;
};

export default GlobalTemporaryAlert;

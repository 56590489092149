import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { Spacing } from "@residently/components/tokens";
import { useCallback, useState } from "react";

import { useOrganisations } from "hooks/organisations";
import useCurrentOrganisationIdContext from "hooks/useCurrentOrganisationIdContext";

interface Props {
  organisationInternalName?: string;
}

const AccountOrganisationPicker = ({ organisationInternalName }: Props) => {
  const { setCurrentOrganisationId } = useCurrentOrganisationIdContext();

  const { data: organisations } = useOrganisations({
    fields: {
      organisations: "internal_name",
    },
  });

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setMenuAnchorEl(event.currentTarget);
    },
    [setMenuAnchorEl]
  );

  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null);
  }, [setMenuAnchorEl]);

  const selectOrganisation = useCallback(
    (id: string) => () => {
      setCurrentOrganisationId(id);
      handleMenuClose();
    },
    [setCurrentOrganisationId, handleMenuClose]
  );

  if (organisationInternalName) {
    const organisationPickerOptions = organisations?.map((org) => (
      <MenuItem key={org.id} onClick={selectOrganisation(org.id)}>
        <Typography>{org.internal_name}</Typography>
      </MenuItem>
    ));

    return (
      <>
        <Button
          sx={{
            minWidth: "100%",
            justifyContent: "flex-start",
            paddingLeft: Spacing.medium,
            paddingRight: Spacing.medium,
          }}
          color="inherit"
          onClick={handleMenuClick}
          aria-controls="simple-menu"
          aria-haspopup="true"
        >
          <Typography>{organisationInternalName}</Typography>
          <ExpandMoreIcon />
        </Button>
        <Menu
          id="account-organisation-picker"
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
        >
          {organisationPickerOptions}
        </Menu>
      </>
    );
  }

  return null;
};

export default AccountOrganisationPicker;

import NextLink from "next/link";
import { usePathname } from "next/navigation";
import { ReactNode } from "react";

import NavListItemContent from "components/organisms/SidebarNav/NavListItemContent";

export interface Props {
  href?: string;
  text: string;
  open: boolean;
  icon?: ReactNode;
  enabled: boolean;
  preview: boolean;
  newTab?: boolean;
  onClick?: () => void;
}

export default function NavListItem({
  href,
  text,
  open,
  icon,
  enabled,
  preview,
  newTab,
  onClick,
}: Props) {
  if (!enabled) {
    return null;
  }

  const asPath = usePathname();

  // NOTE: because /viewings/availability is its own top level nav item, we need to do
  // this check so Viewings and Viewing Availability items aren't both highlighted
  const isViewingAvailability =
    asPath?.startsWith("/viewings/availability") && href === "/viewings";

  // NOTE: because `/applications/*` handles new applications and the `Applications`
  // NavBarItem href is `/dashboard`, handle selected for this URL manually
  const isNewApplication =
    asPath === "/applications/add" && href === "/dashboard";

  const selected =
    (href && asPath?.startsWith(href) && !isViewingAvailability) ||
    isNewApplication;

  const listItemContent = (
    <NavListItemContent
      text={text}
      open={open}
      icon={icon}
      preview={preview}
      selected={selected}
      onClick={onClick}
    />
  );

  if (href) {
    return (
      <NextLink href={href} passHref target={newTab ? "_blank" : undefined}>
        {listItemContent}
      </NextLink>
    );
  }

  return listItemContent;
}

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Chip,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Spacing } from "@residently/components/tokens";
import { ReactNode, useMemo } from "react";

import I18n from "services/I18n";

export interface Props {
  text: string;
  open: boolean;
  icon?: ReactNode;
  preview: boolean;
  selected: boolean;
  onClick?: () => void;
  hasSubItems?: boolean;
}

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  minHeight: 48,
  px: 2.5,
  "&:hover": {
    background: theme.palette.blue.blue700,
  },
  "&.Mui-selected": {
    background: theme.palette.blue.blue600,
  },
  "&.Mui-selected:hover": {
    background: theme.palette.blue.blue600,
  },
}));

const SubItemWrapper = styled(Box)({
  position: "relative",
  width: "100%",
});

const RightArrowWrapper = styled(Box)({
  paddingLeft: Spacing.small,
  position: "absolute",
  right: "-5px",
  top: "calc(50% - 22px)",
});

export default function NavListItemContent({
  text,
  open,
  icon,
  preview,
  onClick,
  selected,
  hasSubItems = false,
}: Props) {
  const renderIconAndText = useMemo(
    () => (
      <>
        {icon && (
          <ListItemIcon
            sx={{
              minWidth: 0,
              color: (theme) => theme.palette.white.white100,
              mt: Spacing.xsmall,
            }}
          >
            {icon}
          </ListItemIcon>
        )}
        <ListItemText
          primaryTypographyProps={{
            fontSize: 14,
            whiteSpace: "nowrap",
            overflow: "hidden",
            color: (theme) => theme.palette.white.white100,
          }}
          primary={text}
          sx={{
            marginTop: icon ? 0 : Spacing.xsmall,
            opacity: open ? 1 : 0,
          }}
        />
        {preview && (
          <Chip
            sx={{ marginBottom: Spacing.xsmall }}
            variant="outlined"
            color="success"
            size="small"
            label={I18n.t("components.navbar.previewLabel")}
          />
        )}
      </>
    ),
    [icon, text, open, preview]
  );

  return (
    <ListItem key={text} disablePadding sx={{ display: "block" }}>
      <StyledListItemButton
        onClick={onClick}
        selected={selected}
        sx={{
          justifyContent: open ? "initial" : "center",
          flexDirection: "column",
        }}
      >
        {hasSubItems ? (
          <SubItemWrapper>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {renderIconAndText}
            </Box>
            <RightArrowWrapper>
              <KeyboardArrowRightIcon
                sx={{
                  marginLeft: Spacing.xsmall,
                  color: (theme) => theme.palette.white.white100,
                }}
              />
            </RightArrowWrapper>
          </SubItemWrapper>
        ) : (
          renderIconAndText
        )}
      </StyledListItemButton>
    </ListItem>
  );
}

import AccountMenu from "components/organisms/AccountMenu";

import { useOrganisation } from "hooks/organisations";
import useAuth from "hooks/useAuth";
import useCurrentOrganisationIdContext from "hooks/useCurrentOrganisationIdContext";

const TopNavBar = () => {
  const { signOut, name, userInitials } = useAuth();

  const { currentOrganisationId } = useCurrentOrganisationIdContext();

  const { data: organisation } = useOrganisation(currentOrganisationId, {
    fields: {
      organisations: ["name", "internal_name"],
    },
  });

  return (
    <AccountMenu
      name={name}
      initials={userInitials}
      organisationInternalName={organisation?.internal_name}
      signOut={signOut}
    />
  );
};

export default TopNavBar;

import { styled } from "@mui/material/styles";
import { ReactNode } from "react";

export interface Props {
  children?: ReactNode;
}

const StyledDrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  background: theme.palette.blue.blue800,
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function DrawerHeader({ children }: Props) {
  return <StyledDrawerHeader>{children}</StyledDrawerHeader>;
}

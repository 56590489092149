"use client";

import { Auth0Provider } from "@auth0/auth0-react";
import { Container } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { PartnerTheme } from "@residently/components/themes";
import { Spacing } from "@residently/components/tokens";
import { ErrorBoundary } from "@sentry/nextjs";
import { ReactNode } from "react";
import { SWRConfig } from "swr";

import { CurrentOrganisationIdProvider } from "context/CurrentOrganisationIdContext";
import { GlobalTemporaryAlertContextProvider } from "context/GlobalTemporaryAlertContext";

import FirebaseProvider from "components/providers/FirebaseProvider";

import GlobalTemporaryAlert from "components/molecules/GlobalTemporaryAlert";
import Hotjar from "components/molecules/Hotjar";
import ZendeskChat from "components/molecules/ZendeskChat";

import SidebarNav from "components/organisms/SidebarNav";

import I18n from "services/I18n";

const Root = ({ children }: { children: ReactNode }) => (
  <ErrorBoundary fallback={<p>{I18n.t("components.navigation.error")}</p>}>
    <Auth0Provider
      domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN || ""}
      clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || ""}
      authorizationParams={{
        redirect_uri: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI,
        audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
      }}
    >
      <FirebaseProvider>
        <ThemeProvider theme={PartnerTheme}>
          <SWRConfig value={{ revalidateOnFocus: false }}>
            <CurrentOrganisationIdProvider>
              <GlobalTemporaryAlertContextProvider>
                <SidebarNav>
                  <Container
                    maxWidth={false}
                    disableGutters
                    sx={{ paddingTop: Spacing.xxlarge, height: "100vh" }}
                  >
                    <GlobalTemporaryAlert />
                    {children}
                  </Container>
                </SidebarNav>
              </GlobalTemporaryAlertContextProvider>
            </CurrentOrganisationIdProvider>
            <ZendeskChat />
            <Hotjar />
          </SWRConfig>
        </ThemeProvider>
      </FirebaseProvider>
    </Auth0Provider>
  </ErrorBoundary>
);

export default Root;
